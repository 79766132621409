import { Component } from '@angular/core';

import { Platform, MenuController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { StorageManagerService, StorageType, AuthenType } from './manager/storage/storage-manager.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AuthenticationHttpService } from './manager/http/route/authentication/authentication-http.service';
import { AlertManagerService } from './manager/alert/alert-manager.service';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  // member, admin, guest
  userRule = "guest"

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private router: Router,
    private storageManager: StorageManagerService,
    private geolocation: Geolocation,
    private auth: AuthenticationHttpService,
    private storage: StorageManagerService,
    private alerts: AlertManagerService,
    private firebase: FirebaseX,
    private alertController: AlertController
  ) {
    this.initializeApp();
    this.getGPS()
    this.schduleRefresh()
    setTimeout(() => {
      this.setupFirebase()
    }, 6000);
  }

  setupFirebase() {
    this.firebase.getToken().then(token => {
      console.log(`The token is ${token}`)
      this.storage.save(StorageType.token_noti, token)
    }, error => {
      console.log("111 error ")
      console.log(error)
    })

    this.firebase.onMessageReceived().subscribe(data => {
      console.log(`FCM message: ${data}`)
      console.log(data)

      let title = "";
      let body = "";
      let id = "";
      let type = "";
      // alert(JSON.stringify(data));

      if (data["body"] != undefined && data["body"] != null) {
        // this.alerts.show(data["body"])
        body = data["body"];
      }

      if (data["title"] != undefined && data["title"] != null) {
        // this.alerts.show(data["body"])
        title = data["title"];
      }

      if ((data["title"] != undefined && data["title"] != null) && (data["title"] != undefined && data["title"] != null)) {
        id = data["id"]
        type = data["type"]
      }

      this.presentAlert(title, body, id, type);

    }, error => {
      console.log("2222 error ")
      console.log(error)
    });
  }

  async presentAlert(title, body, id, type) {
    var btn = [{
      text: 'ปิด',
      handler: data => {
        console.log('Cancel clicked');
      }
    }]
    if (id != "" && type != "") {
      btn.push({
        text: 'ดู',
        handler: data => {
          this.storage.save(StorageType.detail_id, id)
          this.storage.save(StorageType.detail_type, type)
          this.router.navigate(["detail"])
        }
      })
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'แจ้งเตือน',
      subHeader: title,
      message: body,
      buttons: btn
    });

    await alert.present();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  handleLogin() {
    this.router.navigate(["login"])
  }

  handleCreateNews() {
    this.storage.save(StorageType.create_type, "1")
    this.storage.save(StorageType.create_id, "")
    this.router.navigate(["landmark-create"])
  }

  getGPS() {
    const recursiveMethod = () => {
      setTimeout(() => {
        this.getGPS();
      }, 2000)
    }
    this.geolocation.getCurrentPosition().then((resp) => {
      this.storageManager.save(StorageType.lat, resp.coords.latitude + "")
      this.storageManager.save(StorageType.lng, resp.coords.longitude + "")
      // console.log(resp)
      recursiveMethod();
    }).catch((error) => {
      //  console.log('Error getting location', error);
      recursiveMethod();
    });
  }

  handleLogout() {
    this.storage.save(StorageType.authen, AuthenType.guest.toString())
    this.storage.save(StorageType.token_login, "")
    this.alerts.show("ออกจากระบบสำเร็จ")
  }

  schduleRefresh() {
    setTimeout(() => {
      this.checkControl()
    }, 2000)
  }

  checkControl() {
    var currentRule = this.storage.load(StorageType.authen)
    if (currentRule == AuthenType.member.toString()) {
      // login
      if(this.storage.load(StorageType.permission) == "2"){
        // admin
        this.userRule = "admin"
      }else{
        this.userRule = "member"
      }
    } else {
      // guest
      this.userRule = "guest"
    }
    this.schduleRefresh()
  }
}
