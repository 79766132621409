import { Injectable } from '@angular/core';
import { StorageManagerService, StorageType } from 'src/app/manager/storage/storage-manager.service';
import { HttpManagerService } from '../../http-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHttpService {

  constructor(
    public http: HttpManagerService,
    public storage: StorageManagerService
  ) { }

  onLogin(username, password) {
    var data = {
      "user": username,
      "pass": password,
      "token_noti": this.storage.load(StorageType.token_noti)
    }
    console.log(JSON.stringify(data))
    return this.http.onFetch("user_Login", data)
  }

  loadProfile(token) {
    var data = {
      "token": token,
    }
    return this.http.onFetch("user_LoadProfile", data)
  }
  saveProfile(profile, notiList) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "profile": profile,
      "notiList": notiList
    }
    console.log(JSON.stringify(data))
    return this.http.onFetch("user_editProfile", data)
  }

  changePassword(old, news) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "old_pass": old,
      "new_pass": news
    }
    console.log(JSON.stringify(data))
    return this.http.onFetch("user_ChangePassword", data)
  }

  register(model) {
    model["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
    model["profile"]["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
    model["token_noti"] = this.storage.load(StorageType.token_noti)
    console.log(JSON.stringify(model))
    return this.http.onFetch("user_Register", model)
  }

  editProfile(model) {
    model["token"] = this.storage.load(StorageType.token_login)
    return this.http.onFetch("user_editProfile", model)
  }

  resetPassword(model) {
    return this.http.onFetch("user_ForgotPassword", model)
  }

  loadNotiBadge() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
    }
    return this.http.onFetch("noti_LoadBadge", data)
  }

  loadListNotificationList(lastId) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "last_id": lastId
    }
    return this.http.onFetch("noti_LoadData", data)
  }

  loadListCalendar(tab, last) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "type": "" + tab,
      "last_id": last
    }
    return this.http.onFetch("calendar_LoadData", data)
  }

  loadSliderHome() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("home_LoadSlide", data)
  }

  loadMasterSos(tab) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "type": "" + tab
    }
    return this.http.onFetch("inform_LoadMaster", data)
  }

  sendSOS(model) {
    return this.http.onFetch("inform_SubmitData", model)
  }


  filterApprover() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("approve_loadSearch", data)
  }

  loadListApprover(data) {
    return this.http.onFetch("approve_loadList", data)
  }

  submitApprover(dataList) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "data": dataList
    }
    return this.http.onFetch("approve_submit", data)
  }

  workFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("jobs_loadSearch", data)
  }

  workMap() {
    var data;
    var filter = this.storage.load(StorageType.work_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group1": [],
        "group2": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
    }
    return this.http.onFetch("jobs_loadMapList", data)
  }

  workList(tnsId) {
    var data;
    var filter = this.storage.load(StorageType.work_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group1": [],
        "group2": [],
        "last_tns_id": tnsId
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["last_tns_id"] = tnsId
    }
    return this.http.onFetch("jobs_loadDataList", data)
  }

  otopFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("otop_loadSearch", data)
  }


  otopMap() {
    var data;
    var filter = this.storage.load(StorageType.otop_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group1": [],
        "group2": [],
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
    }
    return this.http.onFetch("otop_loadMapList", data)
  }

  otopList(tnsId) {
    var data;
    var filter = this.storage.load(StorageType.otop_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group1": [],
        "group2": [],
        "last_tns_id": tnsId,
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["last_tns_id"] = tnsId
    }
    return this.http.onFetch("otop_loadDataList", data)
  }

  farmFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("plants_loadSearch", data)
  }

  farmMap() {
    var data;
    var filter = this.storage.load(StorageType.farm_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group1": [],
        "group2": [],
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
      data["token"] = this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("plants_loadMapList", data)
  }

  farmList(tnsId) {
    var data;
    var filter = this.storage.load(StorageType.farm_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group1": [],
        "group2": [],
        "last_tns_id": tnsId,
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["last_tns_id"] = tnsId
    }
    return this.http.onFetch("plant_loadDataList", data)
  }

  propertyFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("property_loadSearch", data)
  }
  propertyMap() {
    var data;
    var filter = this.storage.load(StorageType.property_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group1": [],
        "group2": [],
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
      data["token"] = this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("property_loadMapList", data)
  }

  propertyList(tnsId) {
    var data;
    var filter = this.storage.load(StorageType.property_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group1": [],
        "group2": [],
        "last_tns_id": tnsId,
        "price": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["last_tns_id"] = tnsId
    }
    return this.http.onFetch("property_loadDataList", data)
  }

  landmarkFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("travel_loadSearch", data)
  }

  landmarkMap() {
    var data;
    var filter = this.storage.load(StorageType.landmark_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group": []
      }
    } else {
      console.log(filter)
      var datas = JSON.parse(filter)
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "group": datas
      }
    }
    return this.http.onFetch("travel_loadMapList", data)
  }

  landmarkList() {
    var data;
    var filter = this.storage.load(StorageType.landmark_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group": []
      }
    } else {
      var datas = JSON.parse(filter)
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "group": datas
      }
    }
    return this.http.onFetch("travel_loadDataList", data)
  }
  foodFilter() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("hr_loadSearch", data)
  }


  foodMap() {
    var data;
    var filter = this.storage.load(StorageType.food_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "com_id": "1db24c41db7c4a399136e7c39a40449e",
        "hotel": [],
        "rest": []
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["com_id"] = "1db24c41db7c4a399136e7c39a40449e"
    }
    return this.http.onFetch("hr_loadMapList", data)
  }

  foodList(tnsId) {
    var data;
    var filter = this.storage.load(StorageType.food_filter)
    if (filter == "") {
      data = data = {
        "token": this.storage.load(StorageType.token_login),
        "hotel": [],
        "rest": [],
        "last_tns_id": tnsId
      }
    } else {
      data = JSON.parse(filter)
      data["token"] = this.storage.load(StorageType.token_login)
      data["last_tns_id"] = tnsId
    }
    return this.http.onFetch("hr_loadDataList", data)
  }

  homeGridMenu() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("home_loadMenu", data)
  }
  homeListContent() {
    var data = {
      "token": this.storage.load(StorageType.token_login)
    }
    return this.http.onFetch("home_loadContent", data)
  }

  listApplication(platform) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "platform": platform
    }
    return this.http.onFetch("application_loadList", data)
  }

  detail() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "tns_id": this.storage.load(StorageType.detail_id),
      "tns_type": this.storage.load(StorageType.detail_type)
    }
    return this.http.onFetch("trans_loadDetail", data)
  }

  loadMasterCreate() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "id": this.storage.load(StorageType.create_id),
      "type": this.storage.load(StorageType.create_type),
    }
    return this.http.onFetch("Master_LoadData", data)
  }
  
  saveMasterCreate(data) {
    data["token"] = this.storage.load(StorageType.token_login)
    var dataNew = {
      "token": this.storage.load(StorageType.token_login),
      "id": data["id"],
      "type": this.storage.load(StorageType.create_type),
      "value": data["value"]
    }
    console.log(JSON.stringify(dataNew))
    return this.http.onFetch("Master_SubmitData", dataNew)
  }

  loadQuizList() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
    }
    return this.http.onFetch("Questionnaire_loadList", data)
  }

  loadQuizDetail() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "value": {
        "id": this.storage.load(StorageType.quiz_id)
      }
    }
    return this.http.onFetch("Questionnaire_loadDetail", data)
  }
  submitQuizDetail(value) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "value": value
    }
    console.log(data)
    console.log(JSON.stringify(data))
    return this.http.onFetch("Questionnaire_submit", data)
  }
  voteQuizDetail(value) {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "value": value
    }
    console.log(data)
    console.log(JSON.stringify(data))
    return this.http.onFetch("Questionnaire_vote", data)
  }
  deleteQuizDetail() {
    var data = {
      "token": this.storage.load(StorageType.token_login),
      "value": {
        "id": this.storage.load(StorageType.quiz_id)
      }
    }
    return this.http.onFetch("Questionnaire_Delete", data)
  }
}
