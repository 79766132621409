import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from './guard/can-activate-via-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanActivateViaAuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./component/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./component/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./component/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./component/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./component/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'landmark',
    loadChildren: () => import('./component/landmark/landmark.module').then( m => m.LandmarkPageModule)
  },
  {
    path: 'landmark-filter',
    loadChildren: () => import('./component/landmark-filter/landmark-filter.module').then( m => m.LandmarkFilterPageModule)
  },
  {
    path: 'map-show',
    loadChildren: () => import('./component/map-show/map-show.module').then( m => m.MapShowPageModule)
  },
  {
    path: 'map-select',
    loadChildren: () => import('./component/map-select/map-select.module').then( m => m.MapSelectPageModule)
  },
  {
    path: 'landmark-create',
    loadChildren: () => import('./component/landmark-create/landmark-create.module').then( m => m.LandmarkCreatePageModule)
  },
  {
    path: 'food-filter',
    loadChildren: () => import('./component/food-filter/food-filter.module').then( m => m.FoodFilterPageModule)
  },
  {
    path: 'food',
    loadChildren: () => import('./component/food/food.module').then( m => m.FoodPageModule)
  },
  {
    path: 'work',
    loadChildren: () => import('./component/work/work.module').then( m => m.WorkPageModule)
  },
  {
    path: 'work-filter',
    loadChildren: () => import('./component/work-filter/work-filter.module').then( m => m.WorkFilterPageModule)
  },
  {
    path: 'otop',
    loadChildren: () => import('./component/otop/otop.module').then( m => m.OtopPageModule)
  },
  {
    path: 'otop-filter',
    loadChildren: () => import('./component/otop-filter/otop-filter.module').then( m => m.OtopFilterPageModule)
  },
  {
    path: 'farm-shop',
    loadChildren: () => import('./component/farm-shop/farm-shop.module').then( m => m.FarmShopPageModule)
  },
  {
    path: 'farm-shop-filter',
    loadChildren: () => import('./component/farm-shop-filter/farm-shop-filter.module').then( m => m.FarmShopFilterPageModule)
  },
  {
    path: 'property',
    loadChildren: () => import('./component/property/property.module').then( m => m.PropertyPageModule)
  },
  {
    path: 'property-filter',
    loadChildren: () => import('./component/property-filter/property-filter.module').then( m => m.PropertyFilterPageModule)
  },
  {
    path: 'application',
    loadChildren: () => import('./component/application/application.module').then( m => m.ApplicationPageModule)
  },
  {
    path: 'approve',
    loadChildren: () => import('./component/approve/approve.module').then( m => m.ApprovePageModule)
  },
  {
    path: 'approve-filter',
    loadChildren: () => import('./component/approve-filter/approve-filter.module').then( m => m.ApproveFilterPageModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./component/quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'quiz-create',
    loadChildren: () => import('./component/quiz-create/quiz-create.module').then( m => m.QuizCreatePageModule)
  },
  {
    path: 'quiz-do',
    loadChildren: () => import('./component/quiz-do/quiz-do.module').then( m => m.QuizDoPageModule)
  },
  {
    path: 'sos',
    loadChildren: () => import('./component/sos/sos.module').then( m => m.SosPageModule)
  },
  {
    path: 'scanqrcode',
    loadChildren: () => import('./component/scanqrcode/scanqrcode.module').then( m => m.ScanqrcodePageModule)
  },  {
    path: 'profile',
    loadChildren: () => import('./component/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'qrcode-show',
    loadChildren: () => import('./qrcode-show/qrcode-show.module').then( m => m.QrcodeShowPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./component/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
