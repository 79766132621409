import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  constructor() { }

  load(type: StorageType): string{
    return localStorage[type] == undefined ? "" : localStorage[type];
  }

  save(type: StorageType, value: string){
    localStorage[type] = value
  }
}


export enum StorageType {
  authen,
  token_login,
  token_noti,
  lat,
  lng,
  permission, // 1 user, 2 admin
  bk_badge_noti,
  page_detail_id,
  select, // use true, false
  select_lat,
  select_lng,
  select_address,
  approver_filter,
  work_filter,
  map_expand,
  otop_filter,
  farm_filter,
  property_filter,
  landmark_filter,
  food_filter,
  detail_id,
  detail_type,
  create_type,
  create_id,
  url_qrcode,
  url_qrcode_param,
  quiz_id
}

export enum AuthenType {
  guest,
  member
}