import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlterService {

  constructor(
    public loadingController: LoadingController
  ) { }

  show(message: String, state: AlertType = AlertType.success) {
    console.log(message)
    alert(message)
  }

  async startLoading() {
    var loading = await this.loadingController.create({
      message: 'กำลังประมวลผล'
    });
    loading.present();
  }

  stopLoading() {
    // setTimeout(() => {
    //   this.loadingController.dismiss()
    // }, 1000);
    this.loadingController.dismiss()
  }

}

export enum AlertType {
  success,
  failed
}
