import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AlertManagerService } from '../alert/alert-manager.service';

@Injectable({
  providedIn: 'root'
})
export class HttpManagerService {
  
  apiUrl: String = " https://app.cpw.ac.th/SmartLocal/api/"

  constructor(
    public http: HttpClient,
    public alertManager: AlertManagerService
  ) { }

  onFetch(method: String, data): Observable<ArrayBuffer> {
    return this.http.post<any>(this.apiUrl + "" + method + "", data)
  }

  willCheckStateDebug(dao): Boolean {
    var status = dao["status"]
    var message = status["messages"]
    switch (status["type"]) {
      case "11": return true;
      case "21": this.alertManager.show(message); return true;
      case "31": this.alertManager.show(message); return false;
      case "41": this.alertManager.show(message); this.forceToLoginPage(); break;
      default: break;
    }
  }

  test() {
    console.log("test")
  }

  private forceToLoginPage() {
    // TODO:: force to login page and clear storage
    console.log("FORCE TO LOGIN")
  }
}
