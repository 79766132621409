import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import {  AlterService } from 'src/app/service/alter.service';

@Injectable({
  providedIn: 'root'
})
export class AlertManagerService {

  constructor(
    public loadingController: LoadingController,
    private alertController: AlertController,
    private alertSrv: AlterService
  ) { }

  show(message: String, state: AlertType = AlertType.success) {
    console.log(message)
    // alert(message)
    this.presentAlert("", message)
  }

  async presentAlert(title, body) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'แจ้งเตือน',
      // subHeader: title,
      message: body,
      buttons: ["ตกลง"]
    });

    await alert.present();
  }

  async startLoading() {
    var loading = await this.loadingController.create({
      message: 'กำลังประมวลผล'
    });
    loading.present();
  }

  stopLoading() {
    this.loadingController.dismiss()
  }

  startLoadingNew(){
    this.alertSrv.startLoading();
    setTimeout(() => {
      this.stopLoadingNew()
    }, 3000);
  }

  stopLoadingNew(){
    this.alertSrv.stopLoading();
    setTimeout(() => {
      this.alertSrv.stopLoading();
    }, 500);
    setTimeout(() => {
      this.alertSrv.stopLoading();
    }, 1500);
  }
}

export enum AlertType {
  success,
  failed
}
