import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {  AlterService } from './service/alter.service';
import { StorageManagerService } from './manager/storage/storage-manager.service';
import { HttpManagerService } from './manager/http/http-manager.service';
import { AuthenticationHttpService } from './manager/http/route/authentication/authentication-http.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ChangePasswordPipe } from './change-password.pipe';


@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordPipe
    // add component
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    StatusBar,
    SplashScreen, AlterService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StorageManagerService,
    HttpManagerService,
    AuthenticationHttpService,
    Geolocation,
    QRScanner,
    Camera,
    FirebaseX,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
